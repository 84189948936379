.analyticscard {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
}
.analysiscontent {
  display: flex;
  justify-content: space-between;
}
.analyticscard header {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}

.analyticcontentbox {
  margin: 1rem 2rem;
}
.analyticssocial {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
  padding-bottom: 3rem;
}

.analyticssocial header {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}

.analyticssocialbox h5 {
  margin-left: 2rem;
  font-size: 1rem;
  margin-bottom: 2rem;
}
.analyticssocialbox p {
  margin-left: 4rem;
}
.analyticssocialbox article {
  margin-left: 2rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  margin-top: 3rem;
}
.analyticsforensics {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
}
.analyticsforensics header {
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
}
.analyticsforensicsbox {
  display: flex;
  justify-content: space-between;
}

.analyticsforensicsbox article {
  margin-left: 0rem;
  color: rgba(0, 0, 0, 0.6);
  font-size: 1rem;
  margin-top: 3rem;
  text-wrap: nowrap;
}

.cypherbruteimg img {
  height: 0.7rem;
  width: 1rem;
  border-radius: 50%;
}

.forensicsp {
  padding-right: 2rem;
  color: rgba(0, 0, 0, 0.6);
}
.cbrutestatus {
  margin-left: 4rem;
  color: rgba(0, 128, 0, 0.597);
}
.analyticscryptotype {
  display: flex;
  margin-left: 4rem;
}
.pokbox {
  display: flex;
  justify-content: space-between;
}

.pokmain {
  border: 1px solid rgba(0, 0, 0, 0);
  margin-left: 2rem;
  padding-bottom: 1rem;
  background-color: rgba(0, 0, 0, 0.05);
}
.pokboosters {
  margin: 1rem 0 0 4rem;
}
.pokantagonist {
  margin: 1rem 4rem 0 0;
}
.pokboosters,
.pokantagonist,
article {
  color: rgba(0, 0, 0, 0.6);
  font-size: 1.4rem;
  margin-top: 3rem;
  text-wrap: nowrap;
  margin-bottom: 1rem;
}
.pokboosters,
.pokantagonist,
p {
  font-size: 1rem;
}

.pokmain article {
  font-size: 1.5rem;
  margin-left: 4rem;
}
.pokmain p {
  font-size: 1.5rem;
  margin-right: 5rem;
  margin-top: 3rem;
  color: "green";
}
.pokmain {
  display: flex;
  justify-content: space-evenly;
  width: 50%;
}
.analyticsflexbox {
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin-left: 5rem;
}
.analyticsflexbox p {
  margin-top: 2rem;
  font-size: 1.1rem;
  margin-left: 2rem;
}

.pkmainlecture {
  margin: 3rem;
}
.analyticsgeotagplus {
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .analyticsgeotagplus {
    overflow: hidden;
    max-width: 15rem;
  }
  .analyticcontentbox article {
    font-size: 1rem;
    margin-top: 1rem;
  }
  .analyticcontentbox p {
    font-size: 0.7rem;
  }
  .analysiscontent {
    display: flex;
    justify-content: space-between;
    padding: 0 0 0 1rem;
  }
  .analyticcontentbox {
    margin: 1rem 0.5rem;
  }

  .analyticscard {
    max-width: 90%;
    padding: 0;
    margin: 0;
  }

  .analyticssocial {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1%;
    width: 90%;
    padding-bottom: 3rem;
  }

  .analyticssocial header {
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
  }

  .analyticssocialbox h5 {
    margin-left: 2rem;
    font-size: 0.8rem;
    margin-bottom: 2rem;
  }
  .analyticssocialbox p {
    margin-left: 4rem;
    font-size: 0.8rem;
  }
  .analyticssocialbox article {
    margin-left: 2rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    margin-top: 3rem;
  }

  .analyticsforensics {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1%;
    width: 90%;
  }
  .analyticsforensics header {
    font-size: 1.1rem;
    margin-top: 1rem;
    margin-left: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
  }
  .analyticsforensicsbox {
    display: flex;
    justify-content: space-between;
  }

  .analyticsforensicsbox article {
    margin-left: 0rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.9rem;
    margin-top: 3rem;
    text-wrap: nowrap;
  }

  .cypherbruteimg img {
    height: 0.7rem;
    width: 1rem;
    border-radius: 50%;
  }

  .forensicsp {
    padding-right: 2rem;
    color: rgba(0, 0, 0, 0.6);
  }
  .cbrutestatus {
    margin-left: 3rem;
    color: rgba(0, 128, 0, 0.597);
  }
  .analyticscryptotype {
    display: flex;
    margin-left: 2rem;
    font-size: 0.8rem;
  }

  .analyticsaddress {
    margin-right: 0.5rem;
  }

  .pokbox {
    display: flex;
    justify-content: space-between;
  }

  .pokmain {
    border: 1px solid rgba(0, 0, 0, 0);
    margin-left: 2rem;
    padding-bottom: 1rem;
    background-color: rgba(0, 0, 0, 0.05);
  }
  .pokboosters {
    margin: 1rem 0 0 1rem;
  }
  .pokantagonist {
    margin: 1rem 0.5rem 0 1rem;
  }
  .pokboosters,
  .pokantagonist,
  article {
    color: rgba(0, 0, 0, 0.6);
    font-size: 1rem;
    margin-top: 2rem;
    text-wrap: nowrap;
    margin-bottom: 1rem;
  }
  .pokboosters,
  .pokantagonist,
  p {
    font-size: 0.6rem;
  }

  .pokmain article {
    font-size: 1.5rem;
    margin-left: 4rem;
  }
  .pokmain p {
    font-size: 1.5rem;
    margin-right: 5rem;
    margin-top: 3rem;
    color: "green";
  }
  .pokmain {
    display: flex;
    justify-content: space-evenly;
    width: 70%;
  }
  .pokmain article {
    font-size: 1rem;
    margin-top: 2.8rem;
  }
  .pkdisplay p {
    font-size: 1rem;
    margin-left: 1rem;
  }
  .analyticsflexbox {
    display: flex;
    justify-content: space-between;
    width: 70%;
    margin-left: 1rem;
  }
  .analyticsflexbox article {
    font-size: 0.9rem;
  }
  .analyticsflexbox p {
    margin-top: 2rem;
    font-size: 0.9rem;
    margin-left: 1rem;
  }

  .pkmainlecture {
    margin: 3rem;
  }

  .analyticsnewdate {
    margin-left: 3rem;
  }
  .analyticsgeotaglink {
    width: 80%;
    overflow-x: hidden;
  }

  .analyticsgeotagp {
    width: 70%;
    overflow: hidden;
  }
}
