.mainlandingpage {
  width: 100vw;
  height: 100vh;
  background-image: url("/src//assets/landingimg/background/pexels-photo-5952651.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.mainlandingpageb {
  background-image: url("/src//assets/landingimg/background/pexels-photo-5952651.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.landnav {
  display: flex;
  justify-content: space-between;
  padding: 0 1rem;
  color: wheat;
}

.cashbackwarrant {
  display: none;
}

.navitems ul {
  list-style-type: none;
}
.navitems li {
  font-size: 1.3rem;
  display: inline-block;
  padding: 1rem 2rem;
  color: wheat;
}
.loginregisterbox {
  font-size: 1.5rem;
  padding: 1.5rem 2rem;
}

.landingbox {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 10%;

  height: 60%;
}
.landingboxcontent {
  background-color: rgba(0, 0, 0, 0.5);

  min-height: 100%;
  margin-right: 3rem;
  min-width: 25%;
  max-width: 25%;

  border-radius: 5%;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  align-items: center;
  box-shadow: 0 2px 0 rgba(-3px, 5px, 0, 0.218);
  z-index: 10;
}
.landingboxcontent h2 {
  color: wheat;
  font-size: 1.3rem;
  text-wrap: nowrap;
}
.landingboxcontentdetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;

  padding: 0 1rem 0 1rem;

  border-radius: 5%;
}

.landingboxcontent p {
  font-size: 1.1rem;
  text-wrap: wrap;
  color: white;
}
.landingproductimg {
  max-width: 100%;
}

.landingcontentbtn {
  margin-top: 2rem;
  font-size: 1.5rem;
}
.sectionA {
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "a b c";
  gap: 1rem;
  align-items: center;
  color: black;
}

.sectionaboxa {
  grid-area: a;
  min-height: 70%;

  padding: 1.5rem;
  text-align: center;
}

.sectionaboxb {
  grid-area: b;
  padding: 1.5rem;
  min-height: 70%;

  text-align: center;
}
.sectionaboxc {
  grid-area: c;
  padding: 1.5rem;

  min-height: 70%;
  text-align: center;
  font-size: 1.5rem;
}
.sectionaboxa,
.sectionaboxb,
.sectionaboxc,
h5 {
  font-size: 1.2rem;
}

.landingfooter {
  padding-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 100vw;
  margin-top: 0rem;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}
.landingfooterdetails {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 3rem;
}
.landingmainimg {
  max-width: 100%;
  max-height: 100%;
}
.footerlinks {
  color: white;
  text-decoration: none;
}

.landfootercc {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 1rem;
}
.marginlinks {
  margin-left: 1rem;
}
.footerbtnbox {
  display: flex;
  min-width: 100%;
}

.footerbtn {
  min-height: 100%;
  background-color: black;
  color: white;
  width: 100%;
  padding: 0 1rem;
  border-radius: 0;
  border: 0;
  transition: background-color 0.5s ease, transform 0.5s ease;
  display: inline-block;
  position: relative;
}

.footerbtn:hover {
  transform: scale(0.9); /* Scale up on hover */
}
.footeremail {
  width: 60%;
}

.landingheadermobile {
  display: flex;
  width: 95%;
  justify-content: space-between;
  align-items: center;
}
.landingheadermobile h1 {
  cursor: pointer;
  color: wheat;
}
.footerinput {
  height: 100%;
}

/* Login */

.arrowback {
  position: absolute;
  font-size: 1.3rem;
  color: wheat;
  top: 5%;
  left: 2%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;
}
.arrowback:hover {
  text-decoration: underline;
}

.loginwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;
  position: relative;
}
.loginbox {
  min-height: 70%;
  min-width: 30%;
  background-color: rgba(0, 0, 0, 0.65);
  color: wheat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 2rem 5rem 2rem;
  box-sizing: content-box;
  z-index: 10;
}

.loginforms {
  color: white;
  width: 80%;
}
.logininputs {
  width: 100%;
}
.loginsubmitbtn {
  position: relative;
  padding: 0.5rem 40%;
  margin-top: 2rem;
  background: linear-gradient(135deg, #1a1a2e, #090d19);
  color: wheat;
  text-wrap: nowrap;
  border: 2px solid transparent;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  overflow: hidden;
}

.loginsubmitbtn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 100%;
  width: 100%;
  height: 100%;
  border: 2px solid transparent;
  background: linear-gradient(to right, transparent, #c9233e, #0f3460);
  transition: left 0.4s ease-in-out;
  z-index: -1;
}

.loginsubmitbtn:hover::after {
  left: 0;
}

.loginsubmitbtn:hover {
  background: linear-gradient(135deg, #0f3460, #1a1a2e);
  border-color: #932b3cce;
  color: whitesmoke;
}

.loginextras {
  display: flex;
  justify-content: space-between;
  margin-top: 5rem;
  color: white;
}

.linktryout {
  color: wheat;
  text-decoration: none;
}

.linktryout:hover {
  color: rgb(222, 155, 155);
}

/* Register*/
.register {
  position: relative;
}
/* RegisterPage.css */
.parent-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110vh;
  width: 100vw;
  background-image: url("../../assets/landingimg/background/large.jpg");
  padding: 1rem;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.contactinfobox {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  min-width: 100%;
}

.resetpassword {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  margin-top: 4rem;
}

.resetpasswordbox {
  background-color: rgba(0, 0, 0, 0.5);
  color: wheat;
  width: 40%;
  height: 50%;
}

@media (max-width: 480px) {
  .parent-container {
    padding: 0.5rem;
  }
}

.register-page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120vh;
  padding: 0 2rem; /* Padding to ensure spacing on small screens */
  background-image: url("../../assets/landingimg/background/large.jpg");
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.rafpage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}
.rafbuttons {
  display: flex;
  justify-content: space-between;
}

/* Ensure the card stays centered on all screen sizes */
@media (max-width: 768px) {
  .register-card {
    max-width: 80%;
    max-width: auto;
    position: relative;
    transform-style: preserve-3d;
    transition: transform 0.6s;
  }
  .register-card-container {
    position: absolute;
    top: 5%;
    right: 5%;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    perspective: 1000px;
  }
}

@media (max-width: 480px) {
  .register-page-container {
    height: 120vh;
    padding: 0.5rem;
  }
}

.register-card-container {
  position: absolute;
  top: 5%;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  perspective: 1000px;
}

.register-card {
  width: 100%;
  max-width: 400px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
}

.register-flipped {
  transform: rotateY(180deg);
}

.register-card-face {
  position: absolute;
  width: 100%;
  backface-visibility: hidden;
}

.register-front {
  transform: rotateY(0deg);
}

.register-back {
  transform: rotateY(180deg);
}

.register-flip-button {
  margin-top: 1rem;
  font-size: 0.875rem;
  width: 100%;
}

.register-card-content {
  padding: 2rem;
}

.disclaimerbox {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100vw;

  background-image: url("/src//assets/landingimg/background/pexels-photo-5952651.webp");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.disclaimerinfo {
  width: 70%;
  background-color: rgba(0, 0, 0, 0.7);
  padding: 2rem 3rem;
}
.disclaimerinfo header {
  color: wheat;
  text-align: center;
  font-size: 1.3rem;
}
.disclaimerinfo article {
  text-wrap: wrap;
  color: wheat;
  font-size: 1.2rem;
}

.privacypolicy {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.privacybox {
  width: 70%;
  padding: 2rem 3rem;
}
.privacybox p {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .disclaimerinfo header {
    color: wheat;
    text-align: center;
    font-size: 1rem;
    margin-top: 1rem;
  }
  .disclaimerinfo article {
    text-wrap: wrap;
    color: wheat;
    font-size: 0.75rem;
  }

  .register-card-container {
    height: auto;
    padding: 2rem;
  }

  .register-card {
    max-width: 100%;
    width: 90%;
  }

  .register-flip-button {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .register-card-content {
    padding: 1.5rem;
  }

  .register-flip-button {
    font-size: 0.875rem;
  }
}

/* API */

.apiwrapperbox {
  display: flex;
  width: 100%;
  height: 99%;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
}

.apiwrapper {
  display: flex;
  flex-direction: column;

  align-items: center;
  min-width: 50%;
  max-width: 50%;
  min-height: 60%;
  max-height: 60%;
  background-color: rgba(0, 0, 0, 0.5);
  margin-bottom: 5%;

  color: wheat;
}
.apitabshead {
  background-color: rgba(0, 0, 0, 0.4);
}

.apitabsbody {
  overflow-y: scroll;
  padding: 1rem;
}
.apitabsbody article {
  min-width: 100%;
  color: white;
  font-size: 1rem;
  max-width: 100%;
  text-wrap: wrap;
}
.apiwrapperbox h2 {
  text-align: center;
}

@media screen and (max-width: 768px) and (min-width: 426px) {
  .navitems li {
    font-size: 1rem;
    display: inline-block;
    padding: 1rem 1rem;
    color: wheat;
  }
  .loginregisterbox {
    font-size: 1rem;
    padding: 1.5rem 2rem;
  }
  .landingbox {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 10%;

    height: 60%;
  }

  .landingboxcontent h2 {
    color: wheat;
    font-size: 0.9rem;
    text-wrap: nowrap;
  }
  .landingboxcontentdetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    padding: 0 1rem 0 1rem;

    border-radius: 5%;
  }

  .landingboxcontent p {
    font-size: 0.8rem;
    text-wrap: wrap;
    color: white;
  }
  .landingproductimg {
    max-width: 100%;
  }

  .landingcontentbtn {
    margin-top: 5rem;
  }
  .loginsubmitbtn {
    margin-top: 2rem;
    background-color: black;
    color: wheat;
  }
}

@media screen and (max-width: 500px) {
  .landingheadermobile h1 {
    font-size: 1.5rem;
  }
  .mainlandingpage {
    width: 100vw;
    height: 100vh;
    background-image: url("/src//assets/landingimg/background/pexels-photo-5952651.webp");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .landingbox {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15%;
    height: 90%;
  }
  .landingboxcontent {
    min-height: 80%;
    min-width: 80%;
    max-width: 90%;

    border-radius: 5%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin: 0;
    align-items: center;
    z-index: 10;
  }
  .landingboxcontent h2 {
    color: wheat;
    font-size: 1.2rem;
    text-wrap: nowrap;
  }
  .landingboxcontentdetails {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;

    padding: 0 1rem 0 1rem;

    border-radius: 5%;
  }

  .landingboxcontent p {
    font-size: 1rem;
    text-wrap: wrap;
    color: white;
  }
  .landingproductimg {
    max-width: 100%;
    margin-top: 1rem;
  }

  .landingmainimg {
    max-width: 100%;
    max-height: 100%;
  }

  .landingcontentbtn {
    margin-top: 4rem;
    font-size: 1rem;
  }
  .sectionA {
    width: 100vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      "a"
      "b"
      "c";
    gap: 1rem;
    align-items: center;
    color: black;
    margin-top: 1rem;
  }

  .sectionaboxa {
    grid-area: a;

    padding: 0 1.5rem 0 1.5rem;
    text-align: center;
    margin-top: 0;
    height: 100%;
  }

  .sectionaboxb {
    grid-area: b;
    padding: 0 1.5rem 0 1.5rem;
    margin-top: 0;
    height: 100%;

    text-align: center;
  }
  .sectionaboxc {
    grid-area: c;
    padding: 0 1.5rem 0 1.5rem;
    text-align: center;
    height: 100%;
    margin-top: 0;
  }
  .sectionaboxa,
  .sectionaboxb,
  .sectionaboxc,
  h5 {
    font-size: 1rem;
  }
  .landingfooter {
    padding-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100vw;
    margin-top: 10rem;
    color: white;
    background-color: rgba(0, 0, 0, 0.8);
  }
  .landingfooterdetails {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 3rem;
  }
  .landingfooterdetails h5 {
    font-size: 0.8rem;
  }
  .footerlinks {
    color: white;
    text-decoration: none;
  }
  .footerhelplinks {
    margin-left: 1rem;
  }

  .landfootercc {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-bottom: 1rem;
  }
  .landfooterccdetail {
    font-size: 0.8rem;
  }
  .footersocials {
    margin-left: 1rem;
    font-size: 0.8rem;
  }
  .marginlinks {
    margin-left: 0.2rem;
  }
  .footerbtnbox {
    display: flex;
    min-width: 100%;
  }

  .footerbtn {
    min-height: 100%;
    background-color: black;
    color: white;
    width: 100%;
    padding: 0 1rem;
    width: 100%;
    border-radius: 0;
  }
  .footeremail {
    width: 50%;
  }
  .footerinput {
    height: 100%;
  }

  .loginwrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 90%;
    min-height: 100%;
  }

  .logininputs {
    width: 100%;
  }

  .loginbox {
    min-height: 50%;
    min-width: 30%;
    background-color: rgba(0, 0, 0, 0.65);
    color: wheat;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 2rem 2rem 2rem 2rem;
    box-sizing: content-box;
    z-index: 10;
  }

  .loginwelcome {
    font-size: 1rem;
  }

  .loginforms {
    color: white;
    width: 90%;
  }
  .logininputs {
    width: 100%;
  }
  .loginsubmitbtn {
    margin-top: 2rem;
    background-color: black;
    color: wheat;
  }

  .loginextras {
    display: flex;
    justify-content: space-between;
    margin-top: 5rem;
    color: white;
    font-size: 0.8rem;
  }

  .register {
    width: 100vw;
    height: 120vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../../assets/landingimg/background/large.jpg");
  }
  .registerbox {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: inherit;
  }

  .registerform {
    grid-area: b;
    background-color: white;
  }

  .apiwrapperbox {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: space-evenly;
    align-items: center;
    flex-direction: column;
  }

  .apiwrapper {
    display: flex;
    flex-direction: column;

    align-items: center;
    min-width: 50%;
    max-width: 100%;
    min-height: 60%;
    max-height: 80%;
    background-color: rgba(0, 0, 0, 0.5);
    margin-bottom: 5%;

    color: wheat;
  }
  .apitabshead {
    background-color: rgba(0, 0, 0, 0.4);
  }

  .apitabsbody {
    overflow-y: scroll;
    padding: 0.5rem;
  }
  .apitabsbody article {
    min-width: 100%;
    color: white;
    font-size: 1rem;
    max-width: 100%;
    text-wrap: wrap;
  }
  .apitabsbody article p {
    font-size: 1rem;
  }
  .apiwrapperbox h2 {
    font-size: 0.8rem;
    text-wrap: wrap;
    text-align: center;
  }
  .apipadding {
    padding-top: 2rem;
  }
}
