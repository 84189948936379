.statusheader {
  width: 90%;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.statuslocation {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
}
.statuslocationboxinner {
  width: 98%;
  display: flex;
  justify-content: space-between;
}
.locationmargin span {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.mapdatatab {
  overflow-x: hidden;
}

.notavailable {
  font-size: 1rem;
  color: rgba(255, 0, 0, 0.6);
  font-weight: 400;
  font-style: italic;
}

.statusinfo {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}
.statusinfobox {
  width: 80%;
}
.statusdetail {
  font-size: 1.3rem;
  margin: 0.5rem 0.5rem;
  padding: 1rem 5rem;
  color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: space-between;

  width: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}

.statusicons {
  margin-right: 0.5rem;
}

.statusdetail article {
  display: flex;
  justify-content: flex-start;
  margin-right: 1rem;
}
.piconbox {
  display: flex;
  justify-content: end;
  width: 30%;
}

.statuspayment {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.statuspayment header {
  height: 10%;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 0;
  margin-left: 1rem;
  border-bottom: 1px solid black;
  margin-top: 1rem;
}

.statuspaymentbox {
  display: flex;
  justify-content: space-evenly;
  width: 80%;
  margin-top: 1rem;
}

.statusicon {
  font-size: 3rem;
  margin-top: 1rem;
}

.statusbox {
  height: 20%;
}
.statusbox h5 {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.6);
}
.statusboxtype {
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 10rem;
}

.statusboxtype h5 {
  font-size: 1.2rem;
  color: rgba(0, 0, 0, 0.6);
}
.statusboxtypestyle {
  font-size: 1.5rem;
  color: rgba(0, 128, 0, 0.64);
}

.statuspaymentdetails {
  margin: 2rem 0 0 1rem;
  width: 80%;
}
.statusassetworth {
  margin-bottom: 4rem;
}

.statusassetworth article {
  font-size: 1.2rem;
  margin-left: 0.5rem;
  margin-bottom: 1.5rem;
}
.statusassetworth,
.statusassetbill,
span {
  font-size: 0.7rem;
}
.margin1 {
  margin-left: 0.3rem;
}

.statusassetbill p {
  margin-left: 2.5rem;
  font-size: 1.1rem;
  color: rgba(200, 0, 0, 0.749);
}
.statusassetworth p {
  margin-left: 2.5rem;
  font-size: 1.1rem;
  color: green;
}

.statusassetbill article {
  font-size: 1.2rem;
  margin-left: 0.5rem;
}

.statustarget {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 1%;
  width: 90%;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
}

.statustarget header {
  height: 10%;
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 0;
  margin-left: 1rem;
  border-bottom: 1px solid black;
  margin-top: 1rem;
}
.statustargetbox {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem;
  margin-top: 2rem;
}
.statustargetbox article {
  font-size: 1.3rem;
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.8);
}
.statustargetbox p {
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.6);
}
.trackedlinks {
  margin-left: 2rem;
  font-size: 1.5rem;
  padding: 1rem 0;
}

.statustargetbox p:hover {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 1);
  cursor: pointer;
}
.mobilestatuslocation {
  font-size: 0.1rem;
}

.statuslocationmobile {
  overflow-x: hidden;
}
.statuslocationboxinnermobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.locationmarginmobile span {
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.mapdatamobile {
  overflow-x: hidden;
}
.mobilemapdata {
  text-decoration: none;
  width: 1rem;
  overflow-x: hidden;
}
.mobilemapdata p {
  font-size: 0.7rem;
  width: 80%;
  overflow: hidden;
}

.notavailable {
  font-size: 1rem;
  color: rgba(255, 0, 0, 0.6);
  font-weight: 400;
  font-style: italic;
}

@media screen and (max-width: 768px) {
  .statusinfo {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1%;
    width: 90%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }
  .statusinfobox {
    min-width: 100%;
    overflow-x: hidden;
  }
  .statusdetail {
    margin: 0.5rem 0.5rem;
    padding: 0.5rem 2rem;
    color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: space-between;

    min-width: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  }

  .statusicons {
    margin-right: 0.5rem;
  }

  .statusdetail article {
    display: flex;
    justify-content: flex-start;
    margin-right: 0.3rem;
    font-size: 1rem;
  }
  .piconbox {
    display: flex;
    justify-content: center;
    width: 30%;
    align-items: center;
    margin-right: 1rem;
  }
  .statusdetail article {
    font-size: 1rem;
    padding: 1rem;
    margin: 0;
  }

  .statustarget {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1%;
    width: 90%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  .statustarget header {
    height: 10%;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem 0;
    margin-left: 1rem;
    border-bottom: 1px solid black;
    margin-top: 1rem;
  }
  .statustargetbox {
    display: flex;
    justify-content: space-around;
    margin-bottom: 3rem;
    margin-top: 2rem;
  }
  .statustargetbox article {
    font-size: 1rem;
    margin-top: 1rem;
    color: rgba(0, 0, 0, 0.8);
  }
  .statustargetbox p {
    font-size: 0.8rem;
    color: rgba(0, 0, 0, 0.6);
  }
  .trackedlinks {
    margin-left: 2rem;
    font-size: 1rem;
    padding: 1rem 0;
  }

  .statustargetbox p:hover {
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 1);
    cursor: pointer;
  }
  .statuspayment {
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 1%;
    width: 90%;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  .statuspayment header {
    height: 10%;
    font-size: 1.1rem;
    color: rgba(0, 0, 0, 0.6);
    padding: 1rem 0;
    margin-left: 1rem;
    border-bottom: 1px solid black;
    margin-top: 1rem;
  }

  .statuspaymentbox {
    display: flex;
    justify-content: space-evenly;
    width: 80%;
    margin-top: 1rem;
  }

  .statusicon {
    font-size: 2rem;
    margin-top: 1rem;
  }

  .statusbox {
    height: 20%;
    margin-left: 2rem;
  }
  .statusbox h5 {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
  .statusboxtype {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 8.5rem;
  }

  .statusboxtype h5 {
    font-size: 1rem;
    color: rgba(0, 0, 0, 0.6);
  }
  .statusboxtypestyle {
    font-size: 1.1rem;
    color: rgba(0, 128, 0, 0.64);
  }

  .statuspaymentdetails {
    margin: 2rem 0 0 1rem;
    width: 80%;
  }
  .statusassetworth {
    margin-bottom: 4rem;
  }

  .statusassetworth article {
    font-size: 1rem;
    margin-left: 0.5rem;
    margin-bottom: 1.5rem;
  }
  .statusassetworth,
  .statusassetbill,
  span {
    font-size: 0.6rem;
  }

  .statusassetbill p {
    margin-left: 2.5rem;
    font-size: 0.9rem;
    color: rgba(200, 0, 0, 0.749);
  }
  .statusassetworth p {
    margin-left: 2.5rem;
    font-size: 0.9rem;
    color: green;
  }

  .statusassetbill article {
    font-size: 1rem;
    margin-left: 0.5rem;
  }
}
