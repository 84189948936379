.inboxgrid {
  display: grid;
  grid-template-areas: "a b";
  width: 100%;
}

.inboxgridA {
  grid-area: a;
  background-color: antiquewhite;
}

.inboxgridB {
  grid-area: b;
  background-color: burlywood;
}

.headercontainer {
  display: flex;
  justify-content: space-between;
}

.avatar {
  margin-top: 0rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.avatar span {
  margin-top: 0.1rem;
  font-weight: 100;
  color: rgba(40, 135, 70, 0.8);
}

.headerbox {
  box-shadow: 0px 5px 0 rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0 0.3);
}
.imgbox {
  display: flex;
  justify-content: space-between;
  width: 60%;
}

.imgbox h3 {
  font-weight: 400;
}

.imgboxspan {
  margin-top: 0.1rem;
  font-size: 0.9rem;
  box-sizing: content-box;
}

.cardcontainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  align-content: center;
  cursor: pointer;
  background-color: rgba(191, 193, 189, 0.214);
  border-radius: 1%;
  height: 4rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.152);
}
.cardcontainer:hover {
  background-color: rgba(191, 193, 189, 0.414);
}

.carddate {
  color: rgba(0, 0, 0, 0.5);
  box-sizing: content-box;
  margin-top: -1.5rem;
  font-size: 0.8rem;
  text-wrap: nowrap;
}

.cardbox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-items: center;
  align-content: center;
}
.cardname {
  margin-left: 1rem;
}

.cardsender {
  font-size: 1.2rem;
}

.cardsubject {
  font-size: 0.9rem;
  overflow-x: hidden;
}

.searchstyle {
  margin-bottom: 1rem;
}
.wideinboxbox {
  display: flex;
  flex-direction: column;
}

.wideinboxdate {
  font-size: 0.8rem;
  color: rgba(96, 7, 7, 0.293);

  align-self: flex-end;

  font-style: italic;
  margin-right: 1rem;
}

.wideinboxbody {
  font-weight: 400;
  padding: 1rem 1.5rem 1rem 1.5rem;
}

.usericon {
  margin-right: 0.5rem;
}
.userh3 {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.subjecticon {
  margin-right: 0.5rem;
}

.tab.active {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 450;
}

.inboxwidemobile {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "avatar content date";
  margin: 0.7rem 0;
  gap: 0.5rem;
  box-sizing: content-box;
  max-width: 90%;
  padding: 0.5rem 0.2rem 0 0.2rem;
}

.message-body {
  font-size: 1rem;
}

.inboxmobilesubject {
  max-width: 75vw;
  min-width: 70vw;
  overflow: hidden;
}
.inboxmobiledetail {
  font-size: 0.8rem;
  overflow-x: hidden;
  overflow-y: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;

  max-width: 75vw;
  min-width: 70vw;
}
.inboxmobiledetail span {
  overflow-x: hidden;
  width: 70%;
}

.inboxwidemobileavatar {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-area: avatar;
}
.inboxwidemobilecontent {
  box-sizing: content-box;
  grid-area: content;
}
.inboxwidemobiledate {
  grid-area: date;
  margin-right: 1rem;
  font-size: 0.6rem;
  text-wrap: nowrap;
}
.mobileinboxmain {
  margin-top: 1rem;
}

.inboxmobiledata {
  margin: 1rem 0.5rem;
}
.inboxsubjectheader {
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.inboxmobilemessagedetails {
  display: flex;
  justify-content: space-between;
  padding-bottom: 1.2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}
.inboxmobilesender {
  display: flex;
}
.inboxmobilesenderdetails {
  margin-left: 1rem;
  font-weight: 450;
}
.inboxstyleadd {
  display: flex;
  justify-content: space-between;
}
.inboxcompletemessage {
  margin-top: 2.5rem;
  padding: 1rem 1rem;
}
