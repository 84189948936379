.scaminfo {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.1);
  padding-bottom: 2rem;
}

.scaminfocard {
  width: 80%;
  margin-left: 2rem;
  overflow-y: hidden;
  padding: 1rem 0;
}

.scaminfo header {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 0;
  border-bottom: 1px solid black;
  margin-left: 1rem;
}

.scaminfosubject {
  font-weight: 500;
  font-size: 1.3rem;
  margin: 0.5rem 0 0 1rem;
  color: rgba(0, 0, 0, 0.8);
}
.scaminfobody {
  margin-left: 1rem;
  height: 5rem;
}

.scaminfolinks {
  text-decoration: none;
}

.scaminfoside {
  background-color: rgba(0, 0, 0, 0.1);
  height: 100%;
  display: flex;
  flex-direction: column;
}
.scaminfoside header {
  font-size: 1.5rem;
  color: rgba(0, 0, 0, 0.6);
  padding: 1rem 0;
  border-bottom: 1px solid black;
  margin-left: 1rem;
}
.scaminfosidelinks {
  width: 80%;
  text-wrap: wrap;
  align-content: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  padding: 1rem 1rem;
}

.scamupdateslink {
  text-decoration: none;
}

.scaminfodata {
  width: 95%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1rem 2rem;
}
.scaminfodata header {
  font-size: 1.6rem;
  margin: 2rem 1rem 0 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid black;
  color: rgba(0, 0, 0, 0.8);
}
.tagdatebox {
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.tagbox,
.infodatebox {
  color: rgba(0, 0, 0, 0.45);
  margin-bottom: 1.5rem;
}

.updateimgbox {
  width: 95%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 1rem 2rem;
}
.updateimgbox img {
  max-width: 100%;
}
.updatebody {
  font-family: "Roboto", sans-serif;
  line-height: 1.6;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.05);
  margin: 1rem 2rem;
}

.updatenewsbody {
  margin: 1rem 1rem 0 1rem;
  padding: 1rem;
  text-wrap: wrap;
}

.updatesubtopic {
  font-size: 1.4rem;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.6);
}
.updatesubdetail {
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.8);
  margin-top: 0.5rem;
  text-wrap: wrap;
}

@media screen and (max-width: 768px) {
  .scaminfoside {
    display: none;
  }
  .scaminfodatacover {
    margin-top: -12%;
    max-width: 95%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .scaminfodata {
    background-color: rgba(0, 0, 0, 0.1);
    margin: 1rem 2rem;
  }
  .scaminfodata header {
    font-size: 1.1rem;
    margin: 2rem 1rem 0 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid black;
    color: rgba(0, 0, 0, 0.8);
  }
  .tagdatebox {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .tagbox,
  .infodatebox {
    color: rgba(0, 0, 0, 0.45);
    margin-bottom: 1.5rem;
  }

  .updateimgbox {
    width: 90%;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 1rem 2rem;
  }
  .updateimgbox img {
    max-width: 100%;
  }
  .updatebody {
    font-family: "Roboto", sans-serif;
    line-height: 1.6;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.05);
    margin: 1rem 2rem;
  }

  .updatenewsbody {
    margin: 1rem 1rem 0 1rem;
    padding: 1rem;
    max-width: 100%;
  }

  .updatesubtopic {
    font-size: 1.2rem;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.6);
    text-wrap: wrap;
    max-width: 100%;
  }
  .updatesubdetail {
    font-size: 0.9rem;
    color: rgba(0, 0, 0, 0.8);
    margin-top: 0.5rem;
    text-wrap: wrap;
    max-width: 100%;
  }
}
