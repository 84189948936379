.casehistory {
  background-color: rgba(0, 0, 0, 0.1);
  padding: 1rem 0;
}

.paymenthistory {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 3rem;
  padding: 1rem 0;
}
