.fraudtips {
  color: inherit;
}

.headertitle {
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.6);
  width: 80%;
}

.fraudinfolink {
  text-decoration: none;
  padding-left: 2rem;
}

.fraudinfolink h5 {
  margin-left: 1rem;
  font-weight: 400;
  margin: 0.5rem 0 0 1rem;
}

.headertitle:hover {
  color: rgba(0, 0, 0, 1);
}

.fraudtips {
  font-size: 1.2rem;
  margin: 1rem 2rem;
}
.fraudtips h5 {
  padding-top: 4rem;
  font-size: 1.3rem;
}
.fraudtips p {
  font-size: 1rem;
}
.fraudtips b {
  font-size: 1.1rem;
}
.fraudtips li {
  font-size: 1rem;
  margin-top: 0.4rem;
}
.conclusionp {
  padding-bottom: 3rem;
}

.fraudinfohead {
  display: flex;
  font-size: 1.3rem;
  margin-left: 1rem;
}
.fraudtipslinks {
  text-decoration: none;
}
