.case-table-container {
  margin: 1.25rem auto;
  width: 90%;
}

.case-status.active {
  color: green;
}

.case-status.inactive {
  color: red;
}

.expandbutton {
  border-radius: 1.5rem;
  padding: 0.8rem 2rem;
  color: white;
  background-color: #009be5;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
}
.expandbutton:hover {
  background-color: #006db3;
}

.sidelist {
  justify-content: center;
  align-items: center;
  justify-items: center;
}

.sidelist ul {
  list-style: none;
}
.sidelist li {
  background-color: #006eb326;
  box-shadow: -5px -3px 0 rgba(2, 23, 65, 0.255);
}

.sidelisthead {
  color: rgba(0, 0, 0, 0.924);
  height: 2rem;
  font-size: 1.2rem;
  font-weight: 500;
  background-color: #006eb33d;
  display: flex;
  justify-content: center;
  align-items: end;
  width: 100%;
  border-bottom: 0.1rem solid rgba(0, 0, 0, 0.318);
}

.sidelistdetail {
  background-color: #009ce502;
  height: 8rem;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}

.smileicon {
  color: green;
  font-size: 2rem;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.statusfont {
  display: flex;
  justify-content: center;
  margin-top: 0.6rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: rgba(0, 128, 0, 0.907);
}

.grid2 {
  overflow-y: scroll;
  height: 100%;
}

.sidelisttarget {
  background-color: #009ce502;
  height: 6rem;
  overflow-y: scroll;
}

.sidelisttargetinput {
  width: 100%;
}

.fraudtype {
  font-size: 1rem;
  margin-top: 1.4rem;
  color: rgba(0, 0, 0, 0.6);
  font-weight: 300;
  height: 3rem;
}

.tracktypebox {
  width: 100%;
  font-size: 0.8rem;
  margin-left: 0.5rem;
}

.tracktypebox span {
  color: rgba(0, 0, 0, 0.6);
}

.locationbox {
  width: 100%;
}
.casetrackedbox {
  display: flex;
  border: 1px solid black;
  margin-top: 0;
}

.casetrackedboxarticle {
  width: 35%;
  border-right: 1px solid black;
  font-size: 1rem;
  color: rgba(0, 0, 0, 0.518);
  font-weight: 600;
}
.casetrackedbox p {
  margin-left: 0.3rem;
  color: rgba(0, 0, 0, 0.518);
  font-size: 1rem;
  width: 100%;
  text-wrap: nowrap;
}
.ipname {
  font-size: 0.9rem;
}

.casecardbox {
  width: 100%;
  height: 100%;
  background-color: #006eb317;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.caseheader {
  height: 20%;
  max-height: 25%;
  display: flex;
  width: 95%;
  justify-content: space-between;

  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: content-box;

  border-radius: 0.5rem;
}
.caseheaderbox {
  margin: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.casehead span {
  margin-right: 0.3rem;
}
.caseheadsuspect span {
  margin-right: 0.3rem;
}
.caseheadsuspect {
  text-wrap: nowrap;
}

.caseheaddetail {
  font-size: 1.3rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.casebody {
  height: 80%;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  width: 95%;
  justify-content: space-between;

  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: content-box;

  border-radius: 0.5rem;
  align-items: center;
  overflow-x: hidden;
  overflow-y: scroll;
}

.typeandvalue {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.typeandvalue h4 {
  font-size: 0.8rem;
  color: rgba(0, 0, 0, 0.7);
}

.casebodyboxes {
  margin: 0.1rem;
  width: 100%;

  border-radius: 5%;
}

.casebodybox1 article {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin: 1rem;
}
.casebodybox1 p {
  color: rgba(0, 0, 0, 0.6);
  margin: 1rem;
}
.casebodybox2 article {
  font-size: 1.5rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  margin: 1rem;
}
.casebodybox2 p {
  color: rgba(0, 0, 0, 0.6);
  margin: 1rem;
}

.casebodybox1 {
  height: 30%;
  display: flex;
}
.casebodybox2 {
  height: 70%;
}

.casebottons {
  width: 30%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.casetypebottons {
  width: 70%;
  text-wrap: nowrap;
}
.caseexpand {
  color: rgba(255, 255, 255, 0.669);
}
.buttonmargin {
  margin: 0;
}
.casemobilebox {
  display: flex;
  justify-content: space-between;
  height: 7rem;
  background-color: rgba(0, 0, 0, 0.1);
}

.casemobiledate {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.casemobiletarget {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.casemobilemain {
  font-size: 1rem;
  margin-top: 0.5rem;
  padding: 0 0.5rem;
}
.casemobilep {
  font-size: 1.1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
  padding: 0.5rem;
}
.casemobileheader {
  font-size: 1.2rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}
.casemobileheaderp {
  font-size: 0.9rem;
}
.casemobilebody {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
}
.expandbuttonmobile {
  border-radius: 1.5rem;
  padding: 0.8rem 2rem;
  color: white;
  background-color: #009be5;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.875rem;
}
.expandbuttonmobile:hover {
  background-color: #006db3;
}
.casemobilelocation {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
}
.casemobilepayments {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  display: flex;
  padding: 1rem;
  max-width: 100%;
}

.casemobilepayments2 {
  max-width: 80vw;
  overflow-x: hidden;
}
.casemobilesociallinks {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  padding: 1rem;
  max-width: 100%;
}

.casemobilesociallinks2 {
  margin-left: 2rem;
  font-size: 1.5rem;
  padding: 1rem 0;
}
.casemobilestatus {
  background-color: rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
  padding: 1rem;
  max-width: 100%;
}
.casemobilestatusbox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
