/* RecoveryBar container styling */
.recovery-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  margin: 2rem auto;
  font-family: "Arial", sans-serif;
}

/* Bar wrapper */
.recovery-bar {
  position: relative;
  width: 100%;
  height: 20px;
  background: linear-gradient(90deg, #2b2d42, #343a40);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.25);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

/* Bar fill */
.recovery-bar-fill {
  height: 100%;
  background: linear-gradient(90deg, #ff6f91, #ff9671, #ffc75f, #f9f871);
  animation: gradientMove 4s ease infinite;
  transition: width 2s ease-in-out;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(255, 111, 145, 0.5);
}

/* Keyframe animation for dynamic gradient */
@keyframes gradientMove {
  0% {
    background-position: 0%;
  }
  100% {
    background-position: 100%;
  }
}

/* Label styling */
.recovery-bar-label {
  margin-top: 10px;
  font-size: 14px;
  color: #d4d4dc;
  text-shadow: 0 1px 3px rgba(0, 0, 0, 0.7);
}

.recovery-bar-label span {
  font-weight: bold;
  color: #ff6f91;
}

/* Responsive design for mobile */
@media (max-width: 480px) {
  .recovery-bar {
    height: 16px;
  }

  .recovery-bar-label {
    font-size: 12px;
  }
}
