.cyphertoolsmain {
  display: flex;
  justify-content: space-between;
  width: 90%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 3rem;
}

.cypherbox {
  margin: 1rem 0 0 3rem;
  padding-right: 1rem;
}
.cyphertoolsstatus {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 3rem;
}
.cyphertoolsstatus article {
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-left: 1rem;

  border-bottom: 1px solid black;
}
.toolstable {
  font-size: 6.1rem;
}

.errorlogs {
  width: 90%;
  background-color: rgba(0, 0, 0, 0.1);
  margin-left: 3rem;
  padding-bottom: 3rem;
}

.errorlogs article {
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin-left: 1rem;

  border-bottom: 1px solid black;
}

.errorlogs p {
  font-size: 0.8rem;
  color: red;
  margin-left: 2rem;
}

@media screen and (max-width: 768px) {
  .cyphertoolsmain {
    display: flex;
    justify-content: space-between;
    width: 90%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 0.5rem;
  }

  .cypherbox {
    margin: 1rem 0 0 3rem;
    padding-right: 1rem;
  }
  .cyphertoolsstatus {
    width: 90%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 0.5rem;
  }
  .cyphertoolsstatus article {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-left: 1rem;

    border-bottom: 1px solid black;
  }
  .toolstable {
    font-size: 6.1rem;
  }

  .errorlogs {
    width: 90%;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 0.5rem;
    padding-bottom: 3rem;
  }

  .errorlogs article {
    padding-bottom: 1rem;
    padding-top: 1rem;
    margin-left: 1rem;

    border-bottom: 1px solid black;
  }

  .errorlogs p {
    font-size: 0.8rem;
    color: red;
    margin-left: 2rem;
  }
}
