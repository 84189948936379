.grid-container {
  display: grid;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: 1fr;
  grid-template-areas: "header main";
  max-width: 97%;
}

.header {
  grid-area: header;
  overflow: hidden;
  background-color: #101f336f;
}

.main {
  grid-area: main;
}
.logoh1 {
  color: wheat;
  padding: 0 0 0 1rem;
  margin-top: 0;
  margin-left: 1rem;
}
.producthome {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}
.producthomebox {
  background-color: white;
  width: 100%;
  border-radius: 8px;
}
.homepheader {
  display: flex;
  justify-content: space-between;
  padding: 1rem 3rem 0.5rem 3rem;
  background-color: rgba(0, 0, 0, 0.06);
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  border-top: 0.1px solid rgba(0, 0, 0, 0.1);
}
.homeptopic {
  max-width: 70%;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.5rem;
}
.homebutton {
  max-width: 30%;
}

.homepcontent {
  padding: 1rem 4rem;
  color: rgba(0, 0, 0, 0.8);
  box-shadow: 1px 2px 0 rgba(0, 0, 0, 0.2);
}

.usercard {
  max-width: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.admincases {
  padding: 2rem 4rem;
}
.admininbox {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.admininboxcard {
  background-color: rgba(0, 0, 0, 0.05);
  width: 90%;
  padding: 2rem;
  box-shadow: 1px 1px 0 rgba(0, 0, 0, 0.2);
  margin-top: 1rem;
}
.adminsubject {
  font-size: 1.1rem;
  text-wrap: nowrap;
}
.adminbody {
  font-size: 1rem;
  padding: 2rem;
  text-wrap: wrap;
}

.adminsendbox {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.adminsender {
  margin-top: 3rem;
}
.adminsrc {
  margin-top: 2rem;
}
.adminsendsub {
  margin-top: 1rem;
}
.adminsendcontent {
  margin-top: 2rem;
}
.editusermaster {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2rem 4rem;
  flex-direction: column;
}
@media screen and (max-width: 768px) {
  .grid-container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: "main";
    max-width: 100%;
  }
  .header {
    display: none;
  }
  .main {
    width: 100%;
  }

  .mobilnav {
    position: absolute;
    left: 0;
    top: 0;
  }
  .logoh1 {
    color: wheat;
    padding: 0 0 0 0.7rem;
    margin-top: 0;
    margin-left: 1rem;
    font-size: 1.5rem;
  }
  .homeptopic {
    max-width: 70%;
    overflow: hidden;
    color: rgba(0, 0, 0, 0.7);
    font-size: 1.1rem;
  }
}

@media screen and (min-width: 768px) and (max-width: 1300px) {
  .grid-container {
    grid-template-columns: 1fr 4fr;
    grid-template-rows: 1fr;
    grid-template-areas: "header main";
    max-width: 97%;
  }
  .header {
    display: none;
  }
}
