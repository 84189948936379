.helpdeskmain {
  background-color: rgba(0, 0, 0, 0.02);
  width: 80%;
}

.helplinbox {
  padding: 1rem 0 0 2rem;
  text-decoration: none;
  font-size: 1.3rem;
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 1rem;
}

.helpinfohead {
  display: flex;
  font-size: 1.4rem;
  padding: 1rem 2rem 0 0;
}
.helpinfodata {
  width: 87%;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 1rem 2rem;
  padding: 1rem;
}
.helpinfodata h5 {
  font-size: 1.3rem;
}
.helpinfodata h4 {
  font-size: 1.4rem;
}

@media screen and (max-width: 768px) {
  .helpinfodata h4 {
    font-size: 1.1rem;
  }
  .helpinfodata li {
    font-size: 1rem;
  }
  .helpinfodata {
    width: 95%;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 1rem 0;
  }

  .helpinfoheader {
    width: 95%;
    background-color: rgba(0, 0, 0, 0.1);
    margin: 1rem 0.5rem;
  }
}
