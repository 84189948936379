.addcasemodal {
  margin-top: 9rem;
  z-index: 99;
  background-color: white;
  opacity: 1;
}

.profile {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100vh;

  min-height: 100%;
  justify-content: center;
  align-items: center;
  background-image: url("../../../../images/profilebg2.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.profilebox {
  max-width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 2rem 4rem;
  border-radius: 5%;
}

.profileimgbox {
  max-width: 30%;
}
.profiledetails {
  display: flex;
  margin: 0.5rem 0;
}

.profilename,
.profileemail,
.profilecases,
.profilerejected,
.profiletype {
  padding: 0.5rem 1rem;
  color: rgba(0, 0, 0, 0.8);
  font-size: 1.2rem;
  background-color: rgba(0, 0, 0, 0.02);
  min-width: 100%;
  margin-top: 0.3rem;
  font-family: Arial, Helvetica, sans-serif;
}
.profilename {
  display: flex;
}
